import { mapValues } from 'lodash-es';
import locale from '@/locales/en-US';

const LETTERS_C = 'ÂḆÇĐÉƑĜĤÌĴǨŁḾŇÓƤǬŘŞŦÜṼŴẌŸƵ';
const LETTERS_S = 'âḇçđéƒĝĥìĵǩłḿňóƥǭřşŧüṽŵẍÿƶ';

const translationMap = Array.from({ length: 26 }).reduce((acc, _, i) => {
    acc[i + 65] = LETTERS_C[i];
    acc[i + 97] = LETTERS_S[i];
    return acc;
}, {});

const pseudolocalize = str => str.replaceAll(/[a-z]/gi, c => translationMap[c.charCodeAt(0)]);

const translateLiterals = str =>
    str.replaceAll(/([^{]+)?(\{\w+\})?/g, (match, g1, g2) => {
        return pseudolocalize(g1 ?? '') + (g2 ?? '');
    });

const translateLocaleObject = obj =>
    mapValues(obj, value => {
        if (!value) {
            return null;
        } else if (typeof value === 'string') {
            return translateLiterals(value);
        } else if (Array.isArray(value)) {
            return value.map(translateLiterals);
        } else {
            return translateLocaleObject(value);
        }
    });

export default translateLocaleObject(locale);
